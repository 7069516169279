body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slideshow {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #000;
  overflow: hidden;
}

.slick-track {
  height: 100%;
  width: 100%;
  position: relative;
}

.slick-track.transitioning {
  animation: slide-transition 450ms;
  animation-fill-mode: forwards;
}

@keyframes slide-transition {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.slide.next {
  left: 100%;
}

.slide img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
}

.archive-info {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  z-index: 99;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.7));
}

.archive-title {
  background: white;
  color: rgb(160, 22, 22);
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1.4rem;
  width: 250px;
}

.archive-description {
  background: white;
  margin-top: 1rem;
  color: #5a5a5a;
  padding: 1rem 2rem;
}
